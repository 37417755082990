import CollectionIcon from '!svg-react-loader!images/igniter/svgs/collection.svg'
import DownloadIcon from '!svg-react-loader!images/igniter/svgs/download.svg'

import * as React from 'react'
import { Cell } from 'react-foundation'
import { useMediaQuery } from 'react-responsive'
import { ProductThumbnail } from './ProductThumbnail'
import { CollectionCollage } from './CollectionCollage'
import { DebugContext } from '../../Utils/DebugContext'
import { SearchMetricsDebug } from '../../Utils/SearchMetricsDebug'
import { VideoPlayer } from '../../VideoPlayer'

const Routes: any = (window as any).Routes

type ActionDisplayTypes = 'none' | 'inline' | 'overlay'

interface IProps {
  product: IProduct
  thumbnailType?: string
  hideText?: boolean
  actionDisplay?: ActionDisplayTypes
  previewVideo?: boolean
  className?: string
  onClick?: (e: React.SyntheticEvent) => void
}

const DEFAULT_PROPS: Partial<IProps> = {
  thumbnailType: 'mini',
  hideText: false,
  actionDisplay: 'inline',
  previewVideo: false,
  className: 'four-column-grid',
}
const PROP_OVERRIDES: { [key: string]: Partial<IProps> } = {
  'ultra-wides': {
    className: 'two-column-grid',
  },
  'shoots': {
    className: 'three-column-grid',
  },
  'stock-photos': {
    className: 'three-column-grid',
    hideText: true,
    actionDisplay: 'overlay',
    thumbnailType: 'main',
  },
  'stock-videos': {
    className: 'three-column-grid',
    hideText: true,
    actionDisplay: 'overlay',
    previewVideo: true,
    thumbnailType: 'main',
  },
}

const extractTagSlug = ({ tag }: { tag?: Partial<IProductTag> }): string => {
  return tag && tag.name
    ? tag.name.toLowerCase().replace(/\W+/, '-')
    : ''
}

const mergeProps = (...props: Array<Partial<IProps>>) => {
  return props.reduce((previous, next) => ({
    ...previous,
    ...Object.assign(
      {},
      ...Object.entries(next || {})
        .filter(([_, v]) => v !== undefined && v !== null)
        .map(([k, v]) => ({ [k]: v })),
    ),
  }))
}

const Product: React.FunctionComponent<IProps> = (props) => {
  const { product } = props
  const {
    id: productId,
    name,
    collection,
    media_type: mediaType,
    display_as_stack: displayAsStack,
    has_collections: hasCollections,
    duration,
    multiscreen,
    producer,
    link,
    site_nav_parent_tag: siteNavParentTag,
    new_tag_visible: newTagVisible,
    video_url: videoUrl,
    collection_collage_image_urls: collectionCollageImageUrls,
  } = product

  const navTagName = extractTagSlug({ tag: siteNavParentTag })

  const {
    thumbnailType,
    hideText,
    actionDisplay,
    previewVideo,
    className,
    onClick,
  } = mergeProps(DEFAULT_PROPS, PROP_OVERRIDES[navTagName], props)

  const productClasses = [
    'product',
    'product-container',
    newTagVisible ? 'is-new' : '',
    className,
    navTagName,
    multiscreen ? 'multiscreen' : 'singlescreen',
    mediaType,
  ]

  const isMobile = useMediaQuery({ maxWidth: 640 })

  const [playingVideo, setPlayingVideo] = React.useState<boolean>(false)

  const collectionsLink = hasCollections
    ? Routes.igniter_product_collections_product_path({
        id: productId,
      })
    : null

  if (thumbnailType === 'poster') {
    productClasses.push('movie-poster')
  }

  if (duration > 0) {
    productClasses.push('has-duration')
  }
  if (
    navTagName === 'title-graphics' &&
    mediaType === 'still' &&
    displayAsStack === true
  ) {
    productClasses.push('stacked')
  }

  const handleClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      if (onClick) {
        onClick(e)
      }
    },
    [onClick],
  )

  const handleVideoPreview = React.useCallback(
    (show: boolean) => {
      if (!isMobile && previewVideo && videoUrl) {
        setPlayingVideo(show)
      }
    },
    [previewVideo, videoUrl, setPlayingVideo, isMobile],
  )

  const handleCollectionLink = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      window.location.href = collectionsLink
    },
    [collectionsLink],
  )

  const productInfo = (
    <div className="product-info">
      <div className="title" title={name}>
        {newTagVisible ? (
          <span className="new-product-tag tag">NEW</span>
        ) : null}
        <a href={link}>{name}</a>
      </div>
      <div className="subtitle" title={producer.name}>
        <a href={producer.link}>{producer.name}</a>
      </div>
    </div>
  )

  const actions = (
    <div className="actions-div">
      {hasCollections &&
      !window.location.href.includes('collections') &&
      thumbnailType !== 'poster' ? (
        <div className="collections-actions">
          <div className="collection-link">
            <button
              onClick={handleCollectionLink}
              className="action collection-link"
            >
              <CollectionIcon />
            </button>
          </div>
        </div>
      ) : null}

      {!collection ? (
        <div className="product-actions">
          <div className="download-link">
            <button
              className="action product-download"
              data-id={productId}
              onClick={crashdown.setUpProductDownload}
            >
              <DownloadIcon />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )

  const thumbnailURL = product[`${thumbnailType}_image_url`]

  return (
    <Cell className={productClasses.join(' ')}>
      <a
        href={link}
        title={previewVideo ? '' : name}
        className="product-link"
        onClick={handleClick}
        onMouseEnter={() => handleVideoPreview(true)}
        onMouseLeave={() => handleVideoPreview(false)}
      >
        <DebugContext.Consumer>
          {(enabled: boolean) =>
            enabled ? <SearchMetricsDebug product={props.product} /> : null
          }
        </DebugContext.Consumer>
        {!thumbnailURL && collectionCollageImageUrls.length ? (
          <CollectionCollage urls={collectionCollageImageUrls} altText={name} />
        ) : (
          <ProductThumbnail
            url={thumbnailURL}
            altText={name}
            duration={duration}
          />
        )}
        {playingVideo ? (
          <div className="video-container">
            <div>
              <VideoPlayer
                aspectRatio="16:9"
                controls={false}
                autoplay={true}
                loop={true}
                poster={thumbnailURL}
                sources={[{ src: videoUrl, type: 'video/mp4' }]}
                muted={true}
              />
            </div>
          </div>
        ) : null}
        {!isMobile && actionDisplay === 'overlay' ? actions : null}
      </a>
      {!hideText ? productInfo : null}
      {actionDisplay === 'inline' ? actions : null}
    </Cell>
  )
}

export { Product, extractTagSlug }
