import * as React from 'react'
import * as API from '../../Checkout/lib/api'
import { IProps, MembershipPlanCards } from '../../MembershipPlanCards'

export class WorshipTogetherKidsMembershipPlanCards extends React.Component<IProps, {}> {
  public render() {
    const translatedProps = {
      ...this.props,
      purchase_options: this.translatedPurchaseOptions(),
    }
    return (
      <MembershipPlanCards
        {...translatedProps}
      />
    )
  }

  private translatedPurchaseOptions(): API.IPurchaseOption[] {
    const { purchase_options: rawPurchaseOptions } = this.props

    return rawPurchaseOptions.map((option) => {
      const { membership_plan: membershipPlan } = option
      const { code } = membershipPlan
      let newPlanName = membershipPlan.name
      let newIncludedItems = membershipPlan.included_items
      let newBenefits = membershipPlan.benefits
      let includedItemsHeader: React.ReactNode | undefined

      if (code === 'WT-KIDS-AA') {
        newIncludedItems = [
          'Worship Song Audio',
          'Lyric & Hand Motion Videos',
          'Bible Memory Tools',
          'Worship Graphics',
          'Curriculum, Coloring Pages, & more...',
        ]
        newBenefits = [
          'Fresh Content Added Monthly',
          'Discount on Renewal',
          'Full Customer Support',
        ]
        includedItemsHeader = (
          <>
            Unlimited Downloads of <u>all</u> Worship Together Kids Media:
          </>
        )
      } else if (code === 'PREMIUM') {
        newPlanName = 'Kids All-Access + Igniter Premium'
        includedItemsHeader = (
          <>
            Everything in Worship Together Kids All-Access{' '}
            <strong className="primary">PLUS</strong>{' '}
            Igniter Premium:
          </>
        )
      }

      return {
        ...option,
        membership_plan: {
          ...membershipPlan,
          name: newPlanName,
          included_items: newIncludedItems,
          included_items_header: includedItemsHeader,
          benefits: newBenefits,
        },
      }
    })
  }
}
