import '../../../polyfills';
import WebpackerReact from 'webpacker-react';

import { MembershipPlanCards } from '../../../MembershipPlanCards';
import {
  WorshipTogetherKidsMembershipPlanCards,
} from '../../../WorshipTogetherKids/MembershipPlanCards';
import { BucketHeader } from '../../../BucketHeader';
import { HomepageSearchWidget } from '../../../igniter/SearchWidget';
import { ProductBucket } from '../../../ProductBucket';
import { ProductList } from '../../../ProductList';


WebpackerReact.setup({
  BucketHeader,
  HomepageSearchWidget,
  ProductBucket,
  ProductList,
  MembershipPlanCards,
  WorshipTogetherKidsMembershipPlanCards,
});
