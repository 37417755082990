import * as React from 'react'
import { CheckCircleIcon } from '../../igniter/Icons'
import { parameterize } from '../../Utils/StringUtils'

interface IProps {
  header: string | React.ReactNode
  includedItems: string[]
  planName: string
}

export function IncludedItems({ header, includedItems, planName }: IProps) {
  const newEssentialsTag = 'NEW to Essentials!'
  const newStockTag = 'NEW to Igniter!'

  const includedItemListItems = includedItems.map((item) => {
    const newTag = item == 'Countdowns' && planName == 'Essentials' ? newEssentialsTag
                         : item == 'Stock Photos' ? newStockTag
                         : item == 'Stock Videos' ? newStockTag
                         : ''
    return (
      <li key={parameterize(item)} className={parameterize(item)}>
        <CheckCircleIcon />
        {item}
        <span> {newTag}</span>
      </li>
    )
  })

  if (includedItems.length === 0) { return null }

  return (
    <div className="section middle">
      <h4>
        {header}
      </h4>
      <ul className="product-types">
        {includedItemListItems}
      </ul>
    </div>
  )
}

IncludedItems.defaultProps = {
  header: 'Unlimited Downloads of all:',
}
