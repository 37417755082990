import { ProductListDataManagement } from './components/ProductListDataManagement';
import { Product } from './components/Product';
import { ProductGrid } from './components/ProductGrid';

/**
 * There are two components that comprise the ProductList. There is a
 * separation of concerns from display and UI interaction and history and data
 * management.
 *
 * ProductListDataManagement Component
 *    Handles history management and querying of new products. All data is
 *    passed via props to the ProductListInterface component.
 *
 * ProductListInterface Component
 *    Handles the display of the fetched content and user interaction. State
 *    stored here is for UI management only and does not contain any remote
 *    data. All remote data will be passed via props from the wrapping
 *    component.
 *
 * We also export the Product and ProductGrid components for reuse in other
 * parts of the application where we need to display a list of products.
 **/

export const ProductList = ProductListDataManagement;
export { Product };
export { ProductGrid };
